// WarehouseDashboard.jsx
import React from 'react';
import './Dashboard.css';
import podIcon from './assets/punchview.png';
import bookinIcon from './assets/signup-icon.png';
import warehouseIcon from './assets/warehouse-icon.svg';
import moveIcon from './assets/forklift-icon.png';
import stockIcon from './assets/import.png';
import barcodeIcon from './assets/barcode.png';
import { useNavigate } from 'react-router-dom';
import FavouriteButton from './FavouriteButton';

const WarehouseDashboard = () => {
  const navigate = useNavigate();


  const handleBookInClick = () => {
    navigate('/WarehouseBookingInForm');
  };

  const handleWareViewClick = () => {
    navigate('/WarehouseView');
  };

  const handleBarcodeClick = () => {
    navigate('/WarehouseBarcode');
  };

  const handleBookStockClick = () => {
    navigate('/WarehouseBookingStock');
  };

  const handleBookOutClick = () => {
    navigate('/WarehouseBookingOutForm');
  };

  const handleWareMoveClick = () => {
    navigate('/WarehouseMoveForm');
  };

  const handleDriverClick = () => {
    navigate('/DeliveryViewer');
  };

  const handleLoadListClick = () => {
    navigate('/LoadListManager');
  };
  
  return (
    <>
    <FavouriteButton pageUrl={window.location.pathname} />
    <div className="dashboard-container">
      <div className="dashboard-header">
        <h1>Warehouse Dashboard</h1>
        {/* You can add a logout or navigation buttons here if needed */}
      </div>
      <div className="widget-container">
        {/* Add widgets and content specific to the Warehouse Dashboard */}
        <div className="widget" onClick={handleBookInClick}>
                <img src={bookinIcon} alt="Book In" />
                <p>Book In</p>
              </div>
        <div className="widget" onClick={handleWareViewClick}>
                <img src={warehouseIcon} alt="Book In" />
                <p>View Warehouse Stock</p>
              </div>
        <div className="widget" onClick={handleBookOutClick}>
                <img src={bookinIcon} alt="Book Out" />
                <p>Book Out</p>
              </div>
        <div className="widget" onClick={handleWareMoveClick}>
                <img src={moveIcon} alt="Book Out" />
                <p>Move Cylinders</p>
              </div>
        <div className="widget" onClick={handleBarcodeClick}>
                <img src={barcodeIcon} alt="Book Out" />
                <p>Barcode Request Form</p>
              </div>
        <div className="widget" onClick={handleLoadListClick}>
                <img src={barcodeIcon} alt="Book Out" />
                <p>Load List Creator</p>
              </div>
        <div className="widget" onClick={handleDriverClick}>
                <img src={podIcon} alt="Book Out" />
                <p>DriverList</p>
              </div>
      </div>
    </div>
  </>
  );
};

export default WarehouseDashboard;
